import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/logo_mini.png'


const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "card border-light text-center p-0" }
const _hoisted_4 = { class: "card-body pb-5" }
const _hoisted_5 = { class: "h3" }
const _hoisted_6 = { class: "font-weight-normal" }
const _hoisted_7 = { class: "text-gray mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultLoader = _resolveComponent("DefaultLoader")!

  return (_openBlock(), _createBlock(_component_DefaultLoader, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[1] || (_cache[1] = _createElementVNode("div", {
              class: "profile-cover rounded-top",
              "data-bs-background": "@/assets/logo_mini.png"
            }, null, -1)),
            _createElementVNode("div", _hoisted_4, [
              _cache[0] || (_cache[0] = _createElementVNode("img", {
                src: _imports_0,
                class: "user-avatar large-avatar rounded-circle mx-auto mt-n7 mb-4",
                alt: "Neil Portrait"
              }, null, -1)),
              _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.store.getters.profile?.first_name) + " " + _toDisplayString(_ctx.store.getters.profile?.last_name), 1),
              _createElementVNode("h5", _hoisted_6, "@" + _toDisplayString(_ctx.store.state.user.username), 1),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.store.getters.profile?.phone), 1)
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}